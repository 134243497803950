<template>
  <section id="dashboard-ecommerce">
    <h1>test</h1>
  </section>
</template>

<script>

// import { getUserData } from '@/auth/utils'
import { $themeColors } from '@themeConfig'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Vue from 'vue'
import {
  ChartPlugin, LineSeries, Legend, DataLabel, Category, ColumnSeries, AccumulationChartPlugin, PieSeries, AccumulationDataLabel, AccumulationLegend, Export,
} from '@syncfusion/ej2-vue-charts'

Vue.use(ChartPlugin)
Vue.use(AccumulationChartPlugin)

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#BAD000',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  data() {
    return {
      selectedAgent: null,
      fromDate: null,
      toDate: null,
      selectedType: null,
      showLoading: false,
      brokersChartData: [],
      tiaLivesChartData: [],
      tiaPremiumChartData: [],
      leadsBarChartData: [],
      top5BrokersLivesChartData: [],
      top5BrokersPremiumChartData: [],
      top5BrokersDataLabel: {
        // eslint-disable-next-line no-template-curly-in-string
        visible: true, name: 'text', template: '<b>${Number(point.y).toLocaleString()}</b>', font: { fontWeight: 'bold', size: 16, color: '#fff' },
      },
      pointColorMapping: 'fill',
      palettes: ['#BAD000', '#78dbbe', '#E89841'],
      barPalette: ['#e8a55b'],
      primaryXAxis: {
        valueType: 'Category',
        edgeLabelPlacement: 'Shift',
        majorGridLines: { width: 0 },
      },
      chartArea: {
        border: {
          width: 0,
        },
      },
      markerTop: {
        visible: true,
        height: 10,
        width: 10,
        shape: 'Circle',
        dataLabel: {
          // eslint-disable-next-line no-template-curly-in-string
          visible: true, position: 'Auto', angle: 20, template: '<b>${Number(point.y).toLocaleString()}</b>', font: { fontWeight: 'bold', size: 15, color: '#5e5873' },
        },
      },
      markerBottom: {
        visible: true,
        height: 10,
        width: 10,
        shape: 'Circle',
        dataLabel: { visible: true, position: 'Auto', font: { fontWeight: 'bold', size: 15, color: '#5e5873' } },
      },
      legendSettings: {
        visible: true,
        position: 'Top',
        toggleVisibility: true,
      },
      donutLegendSettings: {
        visible: true,
        position: 'Bottom',
        toggleVisibility: true,
      },
      width: '100%',
      data: {},
      option: {
        xAxisData: [],
        series: [],
      },
      tiaLivesOption: {
        xAxisData: [],
        series: [],
      },
      tiaAnnualPremiumOption: {
        xAxisData: [],
        series: [],
      },
      barOption: {
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [],
      },
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
            chartColors.donut.series4,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: false,
                    fontSize: '1.5rem',
                    label: 'Khaled Ahmed',
                    formatter() {
                      return '31'
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      tiaDonutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
            chartColors.donut.series4,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: false,
                    fontSize: '1.5rem',
                    label: 'Khaled Ahmed',
                    formatter() {
                      return '31'
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      supportTrackerRadialBar: {
        series: [],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Closed Meetings'],
        },
      },
    }
  },
  provide: {
    chart: [LineSeries, Legend, DataLabel, Category, ColumnSeries, Export],
    accumulationchart: [PieSeries, AccumulationDataLabel, AccumulationLegend],
  },
  computed: {
    ...mapGetters({
      agentsList: 'users/agentsList',
      insuranceTypeList: 'insuranceTypes/insuranceTypeList',
    }),
    fromDateComputed() {
      return this.fromDate
    },
    toDateComputed() {
      return this.toDate
    },
    agentComputed() {
      return this.selectedAgent
    },
    typeComputed() {
      return this.selectedType
    },
  },
  watch: {
    fromDateComputed() {
      this.loadDashboardFunction()
    },
    toDateComputed() {
      this.loadDashboardFunction()
    },
    agentComputed() {
      this.loadDashboardFunction()
    },
    typeComputed() {
      this.loadDashboardFunction()
    },
  },
  methods: {
    ...mapActions({
      get: 'get',
    }),
    resetFilters() {
      this.fromDate = null
      this.toDate = null
      this.selectedType = null
      this.selectedAgent = null
    },
    ExportChartFunction(chartID) {
      const chart1 = document.getElementById(chartID).ej2_instances[0]
      chart1.exportModule.export('PNG', 'Chart', null, [chart1])
    },
    async loadDashboardFunction() {
      let url = `/admin/dashboard?endDate=${new Date(Date.now()).toISOString()}`
      if (this.fromDate !== null) {
        url += `&startDate=${this.fromDate}`
      }
      if (this.toDate !== null) {
        url += `&endDate=${this.toDate}`
      }
      if (this.selectedAgent !== null) {
        url += `&agent=${this.selectedAgent}`
      }
      if (this.selectedType !== null) {
        url += `&type=${this.selectedType}`
      }
      this.showLoading = true
      await this.get({
        url,
      }).then(response => {
        this.data = response.data
        this.brokersChartData = response.data.brokersChart
        // this.option.xAxisData = response.data.last12Months
        this.barOption.xAxis[0].data = response.data.last12Months
        /* this.tiaLivesOption.xAxisData = response.data.last12Months
        this.tiaLivesOption.series = response.data.tiaLivesChart.series */
        this.tiaLivesChartData = response.data.tiaLivesChart
        this.tiaPremiumChartData = response.data.tiaPremiumChart
        this.leadsBarChartData = response.data.leadsChart
        /* this.tiaAnnualPremiumOption.xAxisData = response.data.last12Months
        this.tiaAnnualPremiumOption.series = response.data.tiaPremiumChart.series */
        // this.option.series = response.data.brokersChart.series
        this.barOption.series = response.data.leadsChart.series
        this.supportTrackerRadialBar.series[0] = response.data.meetingsAnalysis.completedMeetingPercentage
        this.top5BrokersLivesChartData = []
        this.top5BrokersPremiumChartData = []
        response.data.top5BrokersLives.names.forEach(element => {
          this.top5BrokersLivesChartData.push({
            // eslint-disable-next-line radix
            x: `${element.broker.name} ${element.broker.last_name}`, y: parseInt(element.total), text: parseInt(element.total),
          })
        })
        response.data.top5BrokersPremium.names.forEach(element => {
          this.top5BrokersPremiumChartData.push({
            // eslint-disable-next-line radix
            x: `${element.broker.name} ${element.broker.last_name}`, y: parseInt(element.total), text: parseInt(element.total),
          })
        })
        this.showLoading = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error,
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
.echarts {
  width: 100%;
}
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
